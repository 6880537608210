import '../styles/Cast.css';
import CastList from "../data/cast.json";
import { useRef, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import video from '../assets/Website Background Master4.webm';

let carouselPosition = 0;

export default function Cast() {
    const cast = CastList.cast;
    const spotlightRef = useRef([]);
    const carouselRef = useRef([]);
    const carouselWrapperRef = useRef([]);
    const helperRef = useRef([]);

    useEffect(() => {
        carouselRef.current[0].classList.add("active");
        spotlightRef.current[0].classList.add("active-spotlight");
        carouselWrapperRef.current.onscroll = removeHelper;
    }, [])

    const removeHelper = () => {
        helperRef.current.style.display = "none";
    }

    function Spotlight() {
        return(
            <div id="spotlight">
                <p id="handle-helper" ref={ helperRef }>Slide for more <FaArrowRight /></p>
                {cast.map((castMember, index) => 
                <div className='spotlight-inner' key={ castMember.name } ref={ (element) => spotlightRef.current.push(element) }>
                    <img src={castMember.headshot} alt={`${castMember.name} Headshot`} className='headshot'></img>
                    <div className='bio'>
                        <h1 className='name'>{castMember.name}</h1>
                        <h2 className='character'><span>as </span>{castMember.character}</h2>
                        {castMember.bio.map((bio, index) =>
                            <p key={index}>{bio}</p>
                        )}
                    </div>
                </div>
                )}
            </div>
        )
    }

    function CastCarousel() {

        const handleClick = (e) => {
            e.preventDefault();
            setActive(e.target.getAttribute("value"));
        }

        function setActive(index) {
            for (let i = 0; i < carouselRef.current.length; i++) {
                carouselRef.current[i].classList.remove("active");
                spotlightRef.current[i].classList.remove("active-spotlight");
            }
            carouselRef.current[index].classList.add("active");
            spotlightRef.current[index].classList.add("active-spotlight");
        }

        return (
            <div id='cast-carousel' ref={ carouselWrapperRef }>
            {cast.map((castMember, index) =>
                <div 
                    // className={ currentIndex === index ? 'active cast-thumb' : 'cast-thumb' } 
                    className='cast-thumb'
                    key={castMember.name} 
                    value={index} 
                    ref={ (element) => carouselRef.current.push(element) }
                    onClick={handleClick} 
                    style={{
                        zIndex : (cast.length - index),
                        transform : "rotateZ(" + (358 + (Math.random() * 4)) + "deg) translateY(10px)"
                    }}
                >
                    <h3 className='thumb-name'>{castMember.name}</h3>
                    <h4 className='thumb-character'>{castMember.character}</h4>
                </div>
            )}
            </div>
        )
    }

        return (
        <>
            <video id="interview-background" autoPlay loop muted>
                <source src={video} type="video/mp4"></source>
            </video>
            <CastCarousel />
            <Spotlight />
        </>
        // <CastMembers />        
    );
}