import { Link } from "react-router-dom";
import '../styles/404.css';
import video from '../assets/Website Background Master4.webm';
import { FaHouseMedical } from "react-icons/fa6";

export default function NoMatch() {
    return (
      <>
        <video id="interview-background" autoPlay loop muted>
          <source src={video} type="video/mp4"></source>
        </video>
        <div id="no-match">
          <h2>Oops! Looks like you're lost...</h2>
          <p>
            <Link to="/">Go back to your room <FaHouseMedical /></Link>
          </p>
        </div>
      </>
    );
}