import '../styles/Nav.css';
import Links from "../components/Links";
import { IoMenu } from "react-icons/io5";
import { useState } from 'react';

function Navbar() {
  const [dropdownActive, setDropdownActive] = useState(false);

  const dropdownClick = () => {
    setDropdownActive(!dropdownActive);
  }

  return (
    <>
      <nav>
        <a href="/"><h2 id="nav-logo">EMERGE</h2></a>
        <div onClick={dropdownClick}><IoMenu /></div>
        <Links id="nav-items" />
      </nav>
      <div id="mobile-menu" className={ dropdownActive ? "menu-active" : ""}>
        <Links id="mobile-nav-items" />
      </div>
    </>
  );
}

export default Navbar;
