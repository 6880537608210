import '../styles/Hero.css';
import video from '../assets/Website Background Master4.webm';
import { useRef, useEffect } from 'react';
// import poster from '../assets/EmergePoster.webp';

function Hero() {
  const lightboxRef = useRef();
  let lightboxActive = false;

  const handleClick = (e) => {
    e.preventDefault();
    lightboxActive = !lightboxActive;
    if (lightboxActive) { lightboxRef.current.style.display = "flex" }
    else { lightboxRef.current.style.display = "none" }
  }

  // This runs after the component is rendered
  useEffect(() => {
    // Check if GPT is loaded
    if (window.googletag && window.googletag.cmd) {
      // Display the Desktop Leaderboard
      window.googletag.cmd.push(function () {
        window.googletag.display('div-gpt-ad-1740754194253-0');
      });
      // Display the Mobile Leaderboard
      window.googletag.cmd.push(function () {
        window.googletag.display('div-gpt-ad-1740755534210-0');
      });
    }
  }, []);

  return (
    <div id="hero-container">
      <div id="hero-background">
        <video id="hero-video" autoPlay loop muted>
          <source src={video} type="video/mp4"></source>
        </video>
      </div>
      <div id="hero-foreground">
        <div id="hero-top">
          {/* <img alt="Emerge Poster" src={poster}></img> */}
          <div>
            <h1>EMERGE</h1>
            <h2 id="tagline">It's not your ordinary hospital.</h2>
            <h3 id="where-to-watch"> Watch on <a href="https://www.tln.ca/shows/emerge/" target="blank_">TLN</a></h3>
          </div>
        </div>
        <div id="video-container">
          <div style={{padding: "56.25% 0 0 0", position : "relative"}}>
              <iframe src="https://player.vimeo.com/video/871222956?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" style={{ position: "absolute" , top: 0, left: 0 ,width: "100%",height: "100%"}} title="Emerge_Trailer"></iframe>
          </div>
        </div>
        {/* Desktop Leaderboard Ad */}
        <div className="ad-unit desktop-leaderboard">
          <div
            id="div-gpt-ad-1740754194253-0"
            style={{ minWidth: '728px', minHeight: '90px' }}
          ></div>
        </div>
        {/* Mobile Leaderboard Ad */}
        <div className="ad-unit mobile-leaderboard">
            <div
              id="div-gpt-ad-1740755534210-0"
              style={{ minWidth: '300px', minHeight: '50px' }}
            ></div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
